














import { Vue, Component } from "vue-property-decorator";
import NavigationDrawer from "../components/navigation-drawer/NavigationDrawer.vue";
import AppBar from "../components/app-bar/AppBar.vue";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";

import { Action } from "vuex-class";
/**
 * The root for actual application content
 */

@Component({
  components: {
    NavigationDrawer,
    AppBar,
    Breadcrumbs,
  },
})
export default class MainLayout extends Vue {
  private isDrawerOpen = true;
  @Action("fetchIndustries", { namespace: "selectOptions" })
  fetchIndustries!: () => Promise<void>;
  @Action("fetchRegions", { namespace: "selectOptions" })
  fetchRegions!: () => Promise<void>;
  @Action("fetchBuyerTypes", { namespace: "selectOptions" })
  fetchBuyerTypes!: () => Promise<void>;
  @Action("fetchSubIndustries", { namespace: "selectOptions" })
  fetchSubIndustries!: () => Promise<void>;

  created() {
    this.fetchIndustries();
    this.fetchRegions();
    this.fetchBuyerTypes();
    this.fetchSubIndustries();
  }
}
