




import { Component, Watch, Vue } from "vue-property-decorator";
import { capitalizeStart } from "@/utils/string";
import { Breadcrumb } from "@/store/breadcrumbs";
import { Getter, Mutation } from "vuex-class";

@Component
export default class Breadcrumbs extends Vue {
  @Getter("getBreadcrumbs", { namespace: "breadcrumbs" })
  breadcrumbs!: Breadcrumb[];
  @Mutation("set", { namespace: "breadcrumbs" }) setBreadcrumbs!: (
    breadcrumbs: Breadcrumb[]
  ) => void;

  generatedBreadcrumbItems(): Breadcrumb[] {
    const route =
      this.$route.matched[this.$route.matched.length - 1].path.substring(1);
    const routeParts = route.split("/");
    const breadcrumbItems: Breadcrumb[] = [];

    routeParts.forEach((part, index) => {
      if (part[0] === ":") {
        breadcrumbItems.push({
          text: part,
        });
      } else if (part === "") {
        // refactor
      } else {
        breadcrumbItems.push({
          text: capitalizeStart(this.$tc("breadcrumb." + part)),
          href: index + 1 === routeParts.length ? null : `#/${part}`,
        });
      }
    });

    return breadcrumbItems;
  }

  @Watch("$route", { deep: true, immediate: true })
  onRouteChange() {
    this.setBreadcrumbs(this.generatedBreadcrumbItems());
  }
}
