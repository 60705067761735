

































































import { Component, Vue } from "vue-property-decorator";
import Notification from "@/entity/Notification";
import NotificationsList from "@/components/notifications/NotificationsList.vue";
import { State, Action, Mutation } from "vuex-class";

@Component({
  components: {
    NotificationsList,
  },
})
export default class Notifications extends Vue {
  isTooltipOpened = false;
  polling: any = null;

  @State("notifications", { namespace: "notifications" })
  notifications!: Notification[];
  @State("isMenuOpened", { namespace: "notifications" }) isMenuOpened!: boolean;
  @State("totalUnread", { namespace: "notifications" }) totalUnread!: number;
  @State("isLoading", { namespace: "notifications" }) isLoading!: boolean;
  @Action("dismissAll", { namespace: "notifications" })
  dismissAll!: Promise<void>;
  @Action("dismissNotification", { namespace: "notifications" })
  dismissNotification!: (id: number) => Promise<void>;
  @Action("fetchNotifications", { namespace: "notifications" })
  fetchNotifications!: () => Promise<void>;
  @Action("fetchTotalNotifications", { namespace: "notifications" })
  fetchTotalNotifications!: () => Promise<void>;
  @Mutation("setIsMenuOpened", { namespace: "notifications" })
  setIsMenuOpened!: (isOpened: boolean) => void;

  created() {
    this.fetchTotalNotifications();
    this.polling = setInterval(() => {
      this.fetchTotalNotifications();
    }, 30000);
  }

  navigateToHome(): void {
    if (this.$route.name !== "home") this.$router.push("/home");
    this.setIsMenuOpened(false);
  }

  get badgeValue(): string {
    return this.totalUnread > 9 ? "9+" : this.totalUnread.toString();
  }

  onNotificationClicked(): void {
    this.setIsMenuOpened(false);
  }

  onMenuActivatorClick(): void {
    if (this.isMenuOpened) {
      this.setIsMenuOpened(false);
      this.isTooltipOpened = false;
      return;
    }

    this.fetchNotifications();
    this.setIsMenuOpened(true);
    this.isTooltipOpened = false;
  }

  beforeDestroy(): void {
    clearInterval(this.polling);
  }
}
