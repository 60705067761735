



































import { Component, PropSync, Vue } from "vue-property-decorator";
import NavigationItem from "./NavigationItem.vue";
import { Getter } from "vuex-class";
import { NavigationItemType } from "@/components/navigation-drawer/types";

@Component({
  components: {
    NavigationItem,
  },
})
export default class NavigationDrawer extends Vue {
  @PropSync("isDrawerOpen", { default: true, type: Boolean })
  isDrawerOpenLocal!: boolean;
  @Getter("isAdmin", { namespace: "auth" }) isLoggedInAdmin!: boolean;

  settingsSubMenu = [
    {
      route: "/dictionaries",
      icon: "mdi-book-open-variant",
      title: "Branchen",
      hide: !this.isLoggedInAdmin,
    },
    {
      route: "/library",
      icon: "mdi-book-open-variant",
      title: "Tags",
      hide: !this.isLoggedInAdmin,
    },
    {
      route: "/settings",
      icon: "mdi-settings",
      title: "System",
      hide: !this.isLoggedInAdmin,
    },
  ];

  get listItems(): Array<NavigationItemType> {
    return [
      {
        route: "/home",
        icon: "mdi-home",
        title: this.$tc("home", 1),
      },
      {
        route: "/projects",
        icon: "mdi-format-list-checkbox",
        title: this.$tc("project", 2),
      },
      {
        route: "/contacts",
        icon: "mdi-account-box-multiple",
        title: this.$tc("contact", 2),
      },
      {
        route: "/companies",
        icon: "mdi-domain",
        title: this.$tc("company", 2),
      },
      {
        icon: "mdi-google-analytics",
        title: this.$tc("analytics", 2),
        children: [
          {
            route: "/analytics",
            title: "Finanzkennzahlen",
            hide: !this.isLoggedInAdmin,
          },
          {
            route: "/market-analysis",
            title: this.$tc("projectSales.tabTitle"),
          },
        ],
      },
      {
        route: "/consultants",
        icon: "mdi-account-tie",
        title: this.$tc("manageBtUsers", 1),
        hide: !this.isLoggedInAdmin,
      },
      {
        icon: "mdi-settings",
        title: this.$tc("setting", 2),
        hide: !this.isLoggedInAdmin,
        children: [
          {
            route: "/dictionaries",
            title: "Branchen",
            hide: !this.isLoggedInAdmin,
          },
          {
            route: "/library",
            title: "Tags",
            hide: !this.isLoggedInAdmin,
          },
          {
            route: "/settings",
            title: "System",
            hide: !this.isLoggedInAdmin,
          },
        ],
      },
    ];
  }

  get visibleItems(): Array<NavigationItemType> {
    return this.listItems.filter((item) => item.hide !== true);
  }

  get settingsIcon() {
    return "mdi-settings";
  }

  hasChildren(listItem: NavigationItemType) {
    return !listItem.children?.length;
  }
}
