













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NavigationItem extends Vue {
  @Prop({ required: true, type: String }) route!: string;
  @Prop({ type: String }) icon!: string;
  @Prop({ required: true, type: String }) title!: string;
}
